import request from '@/utils/request'


// 查询视频评论列表
export function listComment(query) {
  return request({
    url: '/video/video-comment/list',
    method: 'get',
    params: query
  })
}

// 查询视频评论分页
export function pageComment(query) {
  return request({
    url: '/video/video-comment/page',
    method: 'get',
    params: query
  })
}

// 查询视频评论详细
export function getComment(data) {
  return request({
    url: '/video/video-comment/detail',
    method: 'get',
    params: data
  })
}

// 新增视频评论
export function addComment(data) {
  return request({
    url: '/video/video-comment/add',
    method: 'post',
    data: data
  })
}

// 修改视频评论
export function updateComment(data) {
  return request({
    url: '/video/video-comment/edit',
    method: 'post',
    data: data
  })
}

// 删除视频评论
export function delComment(data) {
  return request({
    url: '/video/video-comment/delete',
    method: 'post',
    data: data
  })
}
