<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="id" prop="id" v-if="formType === 1">
        <a-input v-model="form.id" placeholder="请输入id" />
      </a-form-model-item>
      <a-form-model-item label="视频id" prop="videoId" >
        <a-input v-model="form.videoId" placeholder="请输入视频id" />
      </a-form-model-item>
      <a-form-model-item label="评论内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="发评论人id, user-id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入发评论人id, user-id" />
      </a-form-model-item>
      <a-form-model-item label="回复他人id, user-id" prop="replyUserId" >
        <a-input v-model="form.replyUserId" placeholder="请输入回复他人id, user-id" />
      </a-form-model-item>
      <a-form-model-item label="回复评论id, 上级评论id, zb_video_comment-id" prop="replyCommentId" >
        <a-input v-model="form.replyCommentId" placeholder="请输入回复评论id, 上级评论id, zb_video_comment-id" />
      </a-form-model-item>
      <a-form-model-item label="是否置顶, 0 未置顶, 1 已置顶" prop="topFlag" >
        <a-input v-model="form.topFlag" placeholder="请输入是否置顶, 0 未置顶, 1 已置顶" />
      </a-form-model-item>
      <a-form-model-item label="点赞数量" prop="likeCount" >
        <a-input v-model="form.likeCount" placeholder="请输入点赞数量" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getComment, addComment, updateComment } from '@/api/video/comment'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        videoId: null,

        content: null,

        userId: null,

        replyUserId: null,

        replyCommentId: null,

        topFlag: null,

        likeCount: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        id: [
          { required: true, message: 'id不能为空', trigger: 'blur' }
        ],
        videoId: [
          { required: true, message: '视频id不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '评论内容不能为空', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '发评论人id, user-id不能为空', trigger: 'blur' }
        ],
        topFlag: [
          { required: true, message: '是否置顶, 0 未置顶, 1 已置顶不能为空', trigger: 'blur' }
        ],
        likeCount: [
          { required: true, message: '点赞数量不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        videoId: null,
        content: null,
        userId: null,
        replyUserId: null,
        replyCommentId: null,
        topFlag: null,
        likeCount: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getComment({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateComment(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addComment(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
